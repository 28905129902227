import React from 'react'

import Layout from '../components/layout'

const DayOnFarmPage = () => {
    return (
        <Layout>
            <h1>A Day On The Farm</h1>
        </Layout>
    )
}

export default DayOnFarmPage